import { useApp } from "../providers/app";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Field from "../components/Field";
import { useEffect, useState } from "react";
import {
  GetFromStorage,
  RemoveFromStorage,
  SaveToStorage,
} from "../providers/storage";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Version from "../template/Version";

const REMEMBER_KEY = "rememberedoros3";
const Login = () => {
  const { login, logout } = useApp();

  const [remembered] = useState(!!GetFromStorage(REMEMBER_KEY));

  useEffect(() => {
    logout();
  }, [logout]);

  const onSubmit = async ({ usercode, password, remember }) => {
    if (remember) {
      SaveToStorage(REMEMBER_KEY, usercode);
    } else {
      RemoveFromStorage(REMEMBER_KEY);
    }

    try {
      const response = await axios({
        url: "/user/login",
        method: "POST",
        data: {
          username: usercode,
          password: sha256(password).toString(),
        },
      });

      return login(response.data);
    } catch (error) {
      console.log("🚀 ~ file: Login.jsx:45 ~ onSubmit ~ error:", error);

      return Swal.fire(
        "label.warning",
        error.response?.data?.message || error.toString(),
        "error"
      );
    }
  };

  const Validation = Yup.object().shape({
    usercode: Yup.string().required("Бөглөх шаардлагатай"),
    password: Yup.string().required("Бөглөх шаардлагатай"),
  });

  return (
    <div className="text-center justify-center w-96 mx-auto">
      <div className="text-4xl text-green-100">{"Нэвтрэх"}</div>
      <Formik
        initialValues={{
          usercode: GetFromStorage(REMEMBER_KEY) || "",
          password: "",
          remember: remembered,
        }}
        validationSchema={Validation}
        onSubmit={onSubmit}
      >
        {({}) => (
          <Form className="flex flex-col gap-5 my-10 mt-5">
            <Field
              autoFocus={!remembered}
              name="usercode"
              placeholder={"Хэрэглэгчийн нэр"}
              className="uppercase"
            />
            <Field
              autoFocus={remembered}
              name="password"
              placeholder={"Нууц үг"}
              type="password"
            />
            <Field name="remember" type="checkbox" label={"Нууц үг сануулах"} />
            <button type="submit">{"Нэвтрэх"}</button>
          </Form>
        )}
      </Formik>
      <div className="flex w-full justify-center"></div>
      <Version></Version>
    </div>
  );
};

export default Login;
