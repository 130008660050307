import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { arrayValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Select, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import { startOfDay } from "date-fns";
import { renderMedia } from "../../inputs/FileUpload";
export const ROLE_LIST = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "police",
    value: "police",
  },
  {
    label: "other",
    value: "other",
  },
];

const SystemForm = () => {
  const { id } = useParams();
  const { user } = useApp();
  const { t } = useTranslation();

  const supervisor = ["admin", "teacher"].includes(user.role);

  const props = {
    editable: supervisor,
    isDeletable: supervisor,
    model: "system",
    id,
    init: {
      name: "",
      systemId: "",
    },
    validationSchema: Yup.object().shape({
      name: stringValidation(true),
      systemId: stringValidation(true),
    }),
    view: ({ lesson, description, media, _date }) => (
      <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col gap-4">
          <Field
            name="name"
            label="Системийн нэр"
            disabled={true}
            component={Text}
            type="text"
          />
        </div>
        <div className="flex flex-col gap-4">
          <Field
            name="systemId"
            label="Системийн ID"
            disabled={true}
            component={Text}
            type="text"
          />
        </div>
      </div>
    ),
  };

  return (
    <Form {...props}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="name"
                label="Системийн нэр"
                disabled={disabled}
                component={Text}
                type="text"
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="systemId"
                label="Системийн ID"
                disabled={disabled}
                component={Text}
                type="text"
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default SystemForm;
