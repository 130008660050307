import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { arrayValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Select, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import { startOfDay } from "date-fns";
import { renderMedia } from "../../inputs/FileUpload";
export const ROLE_LIST = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "police",
    value: "police",
  },
  {
    label: "other",
    value: "other",
  },
];

const InvoiceForm = () => {
  const { id } = useParams();
  const { user } = useApp();
  const { t } = useTranslation();

  const supervisor = ["admin", "teacher"].includes(user.role);

  const props = {
    editable: supervisor,
    isDeletable: supervisor,
    model: "invoice",
    id,
    init: {
      lastname: "",
      firstname: "",
      username: "",
      department: "",
      rank: "",
      position: "",
      role: "",
    },
    validationSchema: Yup.object().shape({
      lastname: stringValidation(true),
      firstname: stringValidation(true),
      username: stringValidation(true),
      department: stringValidation(true),
      rank: stringValidation(true),
      position: stringValidation(true),
      role: stringValidation(true),
    }),
    view: ({ lesson, description, media, _date }) => (
      <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col gap-4">
          <Field
            name="number"
            label="Нэхэмжлэх №"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="paidStatus"
            label="Төлөв"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="amount"
            label="Мөнгөн дүн"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="invoiceDate"
            label="Нэхэмжлэхийн огноо"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="system"
            label="Систем"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="road"
            label="Зам"
            disabled={true}
            component={Text}
            type="text"
          />
        </div>
        <div className="flex flex-col gap-4">
          <Field
            name="plateNumber"
            label="Улсын дугаар"
            disabled={true}
            component={Text}
            type="text"
          />
          <Field
            name="cabinNumber"
            label="Арлын дугаар"
            disabled={true}
            component={Text}
            type="text"
          />

          <Field
            name="description"
            label="Тэмдэглэл"
            disabled={true}
            component={Text}
            items={ROLE_LIST}
            labelField="current"
          />
        </div>
      </div>
    ),
  };

  return (
    <Form {...props}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="lastname"
                label="Эцэг/эх/-ийн нэр"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="firstname"
                label="Нэр"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="phone"
                label="Утасны дугаар"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="username"
                label="Нэвтрэх нэр"
                disabled={disabled}
                component={Text}
                type="text"
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="department"
                label="Газар, хэлтэс"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="rank"
                label="Цол"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="position"
                label="Албан тушаал"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="role"
                label="Системийн эрх"
                disabled={disabled}
                component={Text}
                items={ROLE_LIST}
                labelField="current"
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default InvoiceForm;
