import { RiLineChartLine, RiUser3Fill, RiFileSearchLine } from "react-icons/ri";
import { MdPayment } from "react-icons/md";
import { VscLayoutMenubar } from "react-icons/vsc";

const Admin = [
  // {
  //   label: "Хяналтын самбар",
  //   path: "/app/dashboard",
  //   icon: <RiLineChartLine />,
  // },

  { label: "Нэхэмжлэх", path: "/app/invoice", icon: <MdPayment /> },
  { label: "Систем", path: "/app/system", icon: <VscLayoutMenubar /> },
  { label: "Хэрэглэгч", path: "/app/user", icon: <RiUser3Fill /> },
  { label: "Лог", path: "/app/log", icon: <RiFileSearchLine /> },
];

const Teacher = [];

const Parent = [];

const Student = [];

const MENU = {
  admin: Admin,
  teacher: Teacher,
  parent: Parent,
  student: Student,
};

export default MENU;
