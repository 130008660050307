import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Field, Form } from "../../template";
import { arrayValidation, stringValidation } from "../../providers/validations";
import { DatePicker, FileUpload, Select, Text } from "../../inputs";
import { useApp } from "../../providers/app";
import { useTranslation } from "react-i18next";
import { startOfDay } from "date-fns";
import { renderMedia } from "../../inputs/FileUpload";
export const ROLE_LIST = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "police",
    value: "police",
  },
  {
    label: "operator",
    value: "operator",
  },
];

const LogForm = () => {
  const { id } = useParams();
  const { user } = useApp();
  const { t } = useTranslation();

  const supervisor = ["admin", "teacher"].includes(user.role);

  const props = {
    model: "log",
    id,
    init: {},
    validationSchema: Yup.object().shape({
      lastname: stringValidation(true),
      firstname: stringValidation(true),
      username: stringValidation(true),
      department: stringValidation(true),
      rank: stringValidation(true),
      position: stringValidation(true),
      role: stringValidation(true),
    }),
    view: (d) => {
      console.log("🚀 ~ file: LogForm.jsx:123 ~ LogForm ~ props.d:", d);

      return (
        d && (
          <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="info"
                label="Үйлдэл"
                disabled={true}
                component={Text}
                type="text"
              />
              <Field
                name="created"
                label="Огноо"
                disabled={true}
                component={DatePicker}
                type="date"
              />
              <Field
                name="createdby"
                label="Нэвтрэх нэр"
                disabled={true}
                component={Text}
                type="text"
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="ip"
                label="IP хаяг"
                disabled={true}
                component={Text}
                type="text"
              />
              <Field
                name="originalUrl"
                label="URL"
                disabled={true}
                component={Text}
                type="text"
              />
              <Field
                name="method"
                label="Мethod"
                disabled={true}
                component={Text}
                type="text"
              />
              <Field
                name="params"
                label="Params"
                disabled={true}
                component={Text}
                type="text"
              />
              <Field
                name="body"
                label="Body"
                disabled={true}
                component={Text}
                type="textarea"
              />
            </div>
          </div>
        )
      );
    },
  };

  return (
    <Form {...props}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="info"
                label="Үйлдэл"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="created"
                label="Огноо"
                disabled={disabled}
                component={DatePicker}
                type="date"
              />
              <Field
                name="createdby"
                label="Нэвтрэх нэр"
                disabled={disabled}
                component={Text}
                type="text"
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="ip"
                label="IP хаяг"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="originalUrl"
                label="URL"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="method"
                label="Мethod"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="params"
                label="Params"
                disabled={disabled}
                component={Text}
                type="text"
              />
              <Field
                name="body"
                label="Body"
                disabled={disabled}
                component={Text}
                type="textarea"
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default LogForm;
