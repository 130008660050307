import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="my-20 flex-1 flex flex-col justify-center items-center">
      <img alt="" style={{ width: 500 }} src="../images/notfound.png" />
      <h3 className="mt-4">
        <span>404</span> {"Хуудас олдсонгүй."}
      </h3>
      <div
        className="pointer flex items-center text-dark-100"
        onClick={() => {
          navigate(-1);
        }}
      >
        <RiArrowLeftLine className="mr-2" />
        {"Буцах"}
      </div>
    </div>
  );
};

export default NotFound;
