import { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RiCloseFill } from "react-icons/ri";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";

window.addEventListener("storage", (event) => {
  if (event.key === "logged-out") {
    window.location = "/auth/logout";
    window.close();
  }
});

const AppContext = createContext({
  user: null,
  badge: 0,
  login: () => {},
  logout: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
});

const AppProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [drawer, setDrawer] = useState();
  const [drawerWidth, setDrawerWidth] = useState(0);

  const login = async (instance) => {
    setInstance(instance);

    navigate("/app/invoice");
  };

  const logout = () => {
    setInstance();
  };

  const setInstance = (instance) => {
    if (instance) {
      const { token, user } = instance;
      mainApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      SaveToStorage("token", token);

      SaveToStorage("user", JSON.stringify(user));
      setUser(instance.user);
    } else {
      RemoveFromStorage("token");
      RemoveFromStorage("user");

      setUser(null);
    }
  };

  const openDrawer = (content, width = 250) => {
    setDrawer(content);
    setDrawerWidth(width);
  };

  const closeDrawer = () => {
    setDrawer(null);
    setDrawerWidth(0);
  };

  useEffect(() => {
    const user = GetFromStorage("user");
    const token = GetFromStorage("token");

    user && token
      ? setInstance({
          token: token,
          user: JSON.parse(user),
        })
      : logout();
  }, []);

  return (
    <AppContext.Provider
      value={{ user, login, logout, openDrawer, closeDrawer }}
    >
      {drawer && (
        <div
          className="fixed top-0 left-0 bottom-0 right-0 z-30 flex overflow-y-auto"
          onClick={closeDrawer}
        >
          <div
            style={{ width: drawerWidth }}
            className="flex-grow-0 bg-white shadow-md z-50 transition-all relative"
          >
            <div className="absolute top-0 right-0 icon-button text-dark-60">
              <RiCloseFill />
            </div>
            <img src="/logo.svg" className="h-20 my-5 mx-auto" alt="logo" />
            <div className="text-green-100 text-center pb-5 border-b">
              <div>label.school_name</div>
            </div>
            {drawer}
          </div>
          <div className="flex-1 bg-black bg-opacity-80 z-40"></div>
        </div>
      )}
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
export default AppProvider;
