import Swal from "sweetalert2";

export const Alert = Swal.mixin({
  customClass: {
    confirmButton: "swal-btn green",
    cancelButton: "swal-btn primary",
  },
  buttonsStyling: false,
});

export const translateError = (error) => {
  return Alert.fire({
    html: error.message || error,
    icon: "warning",
  });
};

export const successAlert = (path, content) => {
  return Alert.fire({
    html: `${path || "Амжилттай"}<br/>${content ?? ""}`,
    icon: "success",
    timer: content ? undefined : 1000,
  });
};

export const confirmPopup = (path) => {
  return Alert.fire({
    title: "Баталгаажуулах",
    html: path,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Тийм",
    cancelButtonText: "Үгүй",
  });
};
