import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { formatDate, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";
import { BsPlusSquare } from "react-icons/bs";
import { DATE_FORMAT, DATE_FORMAT_VIEW } from "../../defines";
import moment from "moment";
export const ROLE_LIST = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "police",
    value: "police",
  },
  {
    label: "operator",
    value: "operator",
  },
];

const defaultFind = ({ role, ...user }) => {
  if (role === "admin") return null;
  if (role === "teacher") return { $or: [{ createdby: user.id }] };
  if (role === "student") return { class: user.class?.id };
  if (role === "parent" && Array.isArray(user.children))
    return {
      class: {
        $in: user.children.map((child) => child?.student?.class?._id),
      },
    };
  return { _id: null };
};

const LogList = () => {
  const { user } = useApp();

  return (
    <div>
      <div className="w-full flex flex-col gap-5 bg-white p-8">
        <div className="flex flex-row justify-between items-center w-full">
          <h2 className="bordered semibold text-secondary-100">
            {"Үйлдлийн бүртгэл"}
          </h2>
        </div>

        <Table
          url={"/log/table"}
          defaultFind={{}}
          columns={[
            {
              label: "Үйлдэл",
              key: "info",
              sortable: true,
              filter: {
                type: "text",
              },
              render: ({ _id, info }) => (
                <Link to={`view/${_id}`}>
                  <div className=" underline text-secondary-100">{info}</div>
                </Link>
              ),
            },
            {
              key: "ip",
              label: "IP хаяг",
              filter: {
                type: "text",
              },
            },
            {
              key: "originalUrl",
              label: "URL",
              filter: {
                type: "text",
              },
            },
            {
              key: "createdby.username",
              label: "Хэрэглэгчийн нэр",
              filter: {
                type: "text",
              },
            },
            {
              key: "date",
              label: "Огноо",
              filter: {
                type: "date",
              },
              render: ({ created }) => (
                <div className="text-center">
                  {moment(created).format(DATE_FORMAT_VIEW)}
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LogList;
