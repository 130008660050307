import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";

import AppProvider from "./providers/app";

import Loader from "./pages/Loader";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import AppLayout from "./layouts/App";
import AuthLayout from "./layouts/Auth";

import axios from "axios";
import { API_ROOT } from "./defines";

import UserForm from "./components/user/UserForm";
import UserList from "./components/user/UserList";

import LogList from "./components/log/LogList";
import LogFrom from "./components/log/LogForm";

import InvoiceList from "./components/invoice/InvoiceList";
import InvoiceForm from "./components/invoice/InvoiceForm";

import SystemList from "./components/system/SystemList";
import SystemForm from "./components/system/SystemForm";

axios.defaults.baseURL = API_ROOT;
document.addEventListener("wheel", function () {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <AppProvider>
          <div className="h-full w-full flex">
            <Routes>
              <Route path="/" element={<Navigate to="/auth/login" />} />
              <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Login from="inside" />} />
              </Route>
              <Route path="app" element={<AppLayout />}>
                <Route path="user" element={<UserList />} />
                <Route path="user/new" element={<UserForm />} />
                <Route path="user/view/:id" element={<UserForm />} />

                <Route path="system" element={<SystemList />} />
                <Route path="system/new" element={<SystemForm />} />
                <Route path="system/view/:id" element={<SystemForm />} />

                <Route path="invoice" element={<InvoiceList />} />
                <Route path="invoice/view/:id" element={<InvoiceForm />} />

                <Route path="log" element={<LogList />} />
                <Route path="log/view/:id" element={<LogFrom />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppProvider>
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>
);
