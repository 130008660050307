import { NavLink, Link, Outlet } from "react-router-dom";

import { RiLogoutBoxLine } from "react-icons/ri";
import MENU from "./menu";
import { useApp } from "../providers/app";
import Loader from "../pages/Loader";
import Version from "../template/Version";

const AppMenu = ({ role, firstname }) => {
  console.log("🚀 ~ file: App.jsx:10 ~ AppMenu ~ firstname:", firstname);

  return (
    <div>
      <div style={{ width: 300 }} className="bg-white p-5 h-screen">
        <img
          src="/logo.png"
          style={{ width: 120 }}
          className="mx-auto mt-10"
          alt="logo"
        />
        <div className="text-center">
          <Version />
        </div>
        <div className="text-center mt-5 mb-10">
          <div className="text-dark-60">{"Сайн байна уу"}!</div>
          <h2>
            <span className="uppercase font-semibold mx-4">{firstname}</span>
          </h2>
          <div className="text-xs text-dark-100">/{role}/</div>
        </div>
        <div className="flex flex-col">
          {MENU[role].map(({ label, path, icon }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "border-r-4 border-green-100 text-green-100"
                    : "text-dark-40"
                } p-2 w-full flex items-center`
              }
            >
              <div className="mr-2">{icon}</div>
              {`${label}`}
            </NavLink>
          ))}
          <Link
            to="/auth/logout"
            className="text-primary-100 p-2 w-full flex items-center mt-32"
          >
            <div className="mr-2">
              <RiLogoutBoxLine />
            </div>
            {"Гарах"}
          </Link>
        </div>
      </div>
    </div>
  );
};

const AppLayout = () => {
  const { user } = useApp();

  if (!user) return <Loader />;

  return (
    <div className="w-full flex">
      <AppMenu {...user}></AppMenu>
      <div className="p-5 flex-1 overflow-scroll">
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default AppLayout;
