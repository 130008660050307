import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import Table from "../../list/Table";
import { useApp } from "../../providers/app";
import { formatDate, more, translateCell } from "../../providers/format";
import { CreatedBy } from "../../template";
import { BsPlusSquare } from "react-icons/bs";
export const ROLE_LIST = [
  {
    label: "admin",
    value: "admin",
  },
  {
    label: "police",
    value: "police",
  },
  {
    label: "other",
    value: "other",
  },
];

const defaultFind = ({ role, ...user }) => {
  if (role === "admin") return null;
  if (role === "teacher") return { $or: [{ createdby: user.id }] };
  if (role === "student") return { class: user.class?.id };
  if (role === "parent" && Array.isArray(user.children))
    return {
      class: {
        $in: user.children.map((child) => child?.student?.class?._id),
      },
    };
  return { _id: null };
};

const UserList = () => {
  const { user } = useApp();

  return (
    <div>
      <div className="w-full flex flex-col gap-5 bg-white p-8">
        <div className="flex flex-row justify-between items-center w-full">
          <h2 className="bordered semibold text-green-100">{"Хэрэглэгчид"}</h2>
          <Link className="hover:cursor-pointer" to="new">
            <BsPlusSquare size={22} color={"rgba(1, 167, 83, 1)"} />
          </Link>
        </div>

        <Table
          url={"/user/table"}
          defaultFind={{}}
          columns={[
            {
              label: "Хэрэглэгчийн нэр",
              key: "username",
              sortable: true,
              filter: {
                type: "text",
              },
              render: ({ _id, username }) => (
                <Link to={`view/${_id}`}>
                  <div className=" underline text-green-100">{username}</div>
                </Link>
              ),
            },
            {
              label: "Эрх",
              key: "role",
              sortable: true,

              filter: {
                type: "select",
                items: ROLE_LIST,
                axioAdapter: (data) => {
                  return data.map(({ name }) => ({
                    value: name,
                    label: name,
                  }));
                },
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default UserList;
